import ApiService from '@/core/services/api.service';

const featureFlagMixin = {
  data() {
    return {
      featureFlags: [],
    };
  },
  created() {
    this.fetchActiveFeatureFlags();
  },
  methods: {
    fetchActiveFeatureFlags() {
      ApiService.get('feature-flags/get-active')
        .then(({ data }) => {
          this.featureFlags = data.data;
        })
        .catch(() => {
          this.featureFlags = [];
        });
    },
    isFeatureActive(featureFlagName) {
      return this.featureFlags.some(
        (featureFlag) => featureFlag === featureFlagName,
      );
    },
  },
};
export default featureFlagMixin;
